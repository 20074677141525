var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "ejs-grid",
        {
          ref: "time-slots-grid",
          attrs: {
            dataSource: _vm.data,
            allowPaging: true,
            allowFiltering: true,
            allowSorting: true,
            sortSettings: _vm.sortOptions,
            filterSettings: _vm.filterOptions,
            toolbar: _vm.toolbarOptions,
            searchSettings: _vm.searchOptions,
            pageSettings: _vm.pageSettings,
            editSettings: _vm.editSettings,
            toolbarClick: _vm.toolbarClick,
            commandClick: _vm.commandClick
          },
          on: {
            actionComplete: _vm.actionComplete,
            actionFailure: _vm.actionFailure
          }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", {
                attrs: {
                  field: "Id",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Name",
                  headerText: _vm.$t("shared.name"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "StartTime",
                  headerText: _vm.$t("shared.startTime"),
                  width: "130",
                  type: "date",
                  format: "HH:mm"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EndTime",
                  headerText: _vm.$t("shared.endTime"),
                  width: "130",
                  type: "date",
                  format: "HH:mm"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Order",
                  headerText: _vm.$t("shared.order"),
                  width: "80"
                }
              }),
              _c("e-column", {
                attrs: { width: "130", commands: _vm.commands }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }